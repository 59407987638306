import { render, staticRenderFns } from "./k-primary-nav.vue?vue&type=template&id=4b3491c6&scoped=true&"
import script from "./k-primary-nav.vue?vue&type=script&lang=js&"
export * from "./k-primary-nav.vue?vue&type=script&lang=js&"
import style0 from "./k-primary-nav.vue?vue&type=style&index=0&id=4b3491c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b3491c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KContainer from '@web/components/core/k-container'
import KIcon from '@web/components/core/k-icon'
import KLanguageNavSwitcher from '@web/components/core/k-language-nav-switcher'
import KLink from '@web/components/core/k-link'
import KLogo from '@web/components/core/k-logo'
import KMenu from '@web/components/core/k-menu'
import KNavUser from '@web/components/core/k-nav-user'
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {KContainer,KIcon,KLanguageNavSwitcher,KLink,KLogo,KMenu,KNavUser,VBtn})
