<template>
    <div class="tw-flex tw-justify-between tw-items-center tw-py-3 tw-transition-colors tw-duration-200">
        <template v-if="profile">
            <div class="tw-flex tw-items-center tw-space-2-4">
                <div class="tw-flex tw-items-center tw-space-x-4 tw-cursor-pointer sm:tw-hidden" @click="$emit('open-drawer')">
                    <div class="tw-flex tw-border-none tw-rounded-full focus:tw-outline-none focus:tw-shadow-outline-gray">
                        <ka-user-avatar :size="40" />
                    </div>
                    <div>
                        <div class="tw-text-sm tw-leading-5 tw-font-medium">{{ profile.firstName }} {{ profile.lastName }}</div>
                        <div class="tw-text-xs tw-text-gray-500 tw-leading-5">{{ profession }}</div>
                    </div>
                </div>

                <div class="tw-hidden sm:tw-block">
                    <v-menu content-class="tw-mt-2 tw-shadow-none tw-overflow-visible tw-p-2 tw-w-80 tw-font-sans">
                        <template v-slot:activator="{ on }">
                            <div
                                v-on="on"
                                class="tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer tw-rounded-md tw-py-1 tw-px-2 tw-transition-colors tw-duration-200 hover:tw-bg-gray-100"
                            >
                                <div class="tw-flex tw-border-none tw-rounded-full focus:tw-outline-none focus:tw-shadow-outline-gray">
                                    <ka-user-avatar :size="40" />
                                </div>
                                <div>
                                    <div class="tw-text-sm tw-leading-5 tw-font-medium">{{ profile.firstName }} {{ profile.lastName }}</div>
                                    <div class="tw-text-xs tw-text-gray-500 tw-leading-5">{{ profession }}</div>
                                </div>
                            </div>
                        </template>
                        <div class="tw-rounded tw-shadow-md">
                            <div class="tw-py-6 tw-px-6 tw-bg-white tw-rounded tw-shadow-sm tw-flex tw-flex-col tw-space-y-3">
                                <k-link :to="{ name: 'profile' }" class="tw-px-4 tw-py-1 tw-text-black tw-no-underline">
                                    <span class="tw-inline-flex tw-flex-col">
                                        <span class="tw-text-base"> {{ profile.firstName }} {{ profile.lastName }} </span>
                                        <span v-if="profession" class="tw-text-gray-500 tw-text-sm">
                                            {{ profession }}
                                        </span>
                                        <span v-else class="tw-text-koderia">
                                            {{ $t('profile.actions.fill_position') }}
                                        </span>
                                    </span>
                                </k-link>

<!--                                <div class="tw-mx-4 tw-max-w-full tw-h-px tw-bg-gray-300">-->
<!--                                    &lt;!&ndash; Divider &ndash;&gt;-->
<!--                                </div>-->

<!--                                <k-link-->
<!--                                    :to="{ name: 'profile-saved' }"-->
<!--                                    class="tw-px-4 tw-pt-2 tw-text-black tw-no-underline hover:tw-underline focus:tw-underline"-->
<!--                                >-->
<!--                                    <span class="tw-inline-flex tw-items-center">-->
<!--                                        <k-icon name="heart_border" />-->
<!--                                        <span class="tw-ml-2">{{ $t('global.titles.saved') }}</span>-->
<!--                                    </span>-->
<!--                                </k-link>-->

                                <div class="tw-mx-4 tw-max-w-full tw-h-px tw-bg-gray-300">
                                    <!-- Divider -->
                                </div>

                                <k-link
                                    :to="{ name: 'profile' }"
                                    class="tw-px-4 tw-py-1 tw-text-black tw-no-underline hover:tw-underline focus:tw-underline"
                                >
                                    {{ $t('navigation.settings') }}
                                </k-link>

                                <a
                                    @click="$store.dispatch('AUTH/signOut')"
                                    class="tw-px-4 tw-py-1 tw-text-black tw-no-underline hover:tw-underline focus:tw-underline"
                                >
                                    {{ $t('auth.actions.logout') }}
                                </a>
                            </div>
                        </div>
                    </v-menu>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="tw-w-full tw-space-y-2 sm:tw-space-x-2 sm:tw-space-y-0 sm:tw-w-auto">
                <k-button id="gtm_sign_in" color="secondary" class="tw-w-full sm:tw-w-auto" :to="{ name: 'auth-signin', params: { locale: routeLocale } }">
                    {{ $t('auth.actions.sign_in_simple') }}
                </k-button>

                <k-button id="gtm_sign_up" color="primary" class="tw-w-full sm:tw-w-auto" :to="{ name: 'auth-signup', params: { locale: routeLocale } }">
                    {{ $t('auth.actions.sign_up_simple') }}
                </k-button>
            </div>
        </template>
    </div>
</template>

<script>
import KaUserAvatar from "@web/components/ka-user-avatar";
import KButton from '@web/components/core/k-button'
import {getRouteLocale} from '@web/constants/language'
import {mapGetters} from "vuex";

export default {
    components: {KButton, KaUserAvatar },
    props: {
        variant: {
            type: String,
            default: "light",
            validator: function(value) {
                return ["light", "dark"].indexOf(value) !== -1;
            }
        }
    },

    data() {
        return {
            userDrawer: false
        };
    },

    computed: {
        ...mapGetters('USER', {
            profile: 'getUserProfile'
        }),
        routeLocale() {
            return getRouteLocale()
        },

        profession() {
            return this.profile.profession?.displayString ?? ''
        },
    }
};
</script>
